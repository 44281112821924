<template>
  <component
    :is="HeaderModuleComponents[module.__typename]"
    v-if="module && '__typename' in module"
    :data-module="module.__typename"
    :data="module"
    class="header-module"
  >
  </component>
</template>

<script setup lang="ts">
import type { Component } from 'vue';
import HeaderHero from '~/components/header-modules/HeaderHero.vue';

type Module = {
  id: string;
  __typename: string;
  [key: string]: unknown;
};

const HeaderModuleComponents: Record<string, Component> = {
  HeaderHeroRecord: HeaderHero,
};

defineProps<{
  module: Module | {};
}>();
</script>

<style scoped lang="scss">
.header-module {
  position: relative;

  > .mark {
    margin: var(--base-component-padding);
    display: block;
    font-size: 1rem;
    background-color: #ffad8f;
    width: fit-content;
  }
}
</style>
