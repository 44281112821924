export function useWordCarousel(connectedWords: string) {
  const words = connectedWords.split(',');

  const currentIndex = ref(0);

  const currentWord = computed(() => {
    return words[currentIndex.value];
  });

  return { words, currentWord, startAnimation };

  function startAnimation() {
    setInterval(function () {
      currentIndex.value = currentIndex.value + 1;

      if (currentIndex.value === words.length) {
        currentIndex.value = 0;
      }
    }, 2500);
  }
}
