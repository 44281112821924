import gsap from 'gsap';
import { useViewportSize } from '~/utils/useViewportSize';

export function useHorizontalScrolling(
  scrollContainer: Ref<HTMLElement | null>,
  options: {
    viewport: 'vl' | 'vs' | 'vl,vs';
  },
) {
  const { isVl, isVs } = useViewportSize();

  const timeline = ref<gsap.core.Timeline | null>(null);

  watch(isVl, function () {
    init();
  });

  onMounted(function () {
    init();
  });

  onBeforeUnmount(function () {
    if (timeline.value && scrollContainer.value) {
      const x = gsap.getProperty(scrollContainer.value, 'x');

      timeline.value.scrollTrigger?.disable();
      timeline.value.pause();

      gsap.set(scrollContainer.value, {
        x,
      });
    }
  });

  function init() {
    if (isMatchingViewport() && scrollContainer.value) {
      timeline.value = getHorizontalScrollTimeline(scrollContainer.value);

      document.documentElement.setAttribute(
        'data-scroll-direction',
        'horizontal',
      );
    } else {
      timeline.value?.scrollTrigger?.kill();
      timeline.value?.kill();
      gsap.killTweensOf(scrollContainer.value);

      document.documentElement.removeAttribute('data-scroll-direction');
    }
  }

  return { timeline };

  function isMatchingViewport() {
    if (options.viewport === 'vl,vs') {
      return true;
    }

    if (options.viewport === 'vl' && isVl.value) {
      return true;
    }

    return options.viewport === 'vs' && isVs.value;
  }

  function getHorizontalScrollTimeline(scrollContainer: HTMLElement) {
    const tl = gsap.timeline({
      id: 'ScrollTimeline',
      ease: 'none',
      paused: true,
      scrollTrigger: {
        trigger: scrollContainer,
        start: 0,
        end: scrollContainer.offsetWidth,
        // end: () => '+=' + scrollContainer.offsetWidth + 'px',
        scrub: 0.5,
        pin: true,
        invalidateOnRefresh: true,
        onUpdate: function (self) {
          window.HorizontalScrollProgress = self.progress;
          window.HorizontalScrollPosition = self.progress * self.end;
        },
      },
      defaults: {
        ease: 'none',
      },
    });

    tl.to(scrollContainer, {
      ease: 'none',
      x: () => -(scrollContainer.scrollWidth - window.innerWidth) + 'px',
    });

    return tl;
  }
}
